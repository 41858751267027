import Router from 'next/router';

const redirectFirstPage = ({ page, res, asPath }) => {
	if (+page === 1) {
		// eslint-disable-next-line no-useless-escape
		const redirectUrl = asPath.replace(/[&\?]?page=1[&]?/gm, match => {
			if (match === '&page=1') return '';
			if (match === '?page=1') return '';
			if (match === '?page=1&') return '?';
		});
		if (res) {
			res.writeHead(301, {
				Location: redirectUrl,
			});
			res.end();
		} else {
			Router.push(redirectUrl);
		}
	}
};

export default redirectFirstPage;
