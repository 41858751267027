import React, { useEffect } from 'react';
import {
	fetchProducts,
	getCatalogData,
	saveSort,
} from '@fh-components/fh-js-api-core/store/slices/catalogSlice';
import { catalogSortSelector } from '@/store';
import { getCategoryTreeBySlug } from '@fh-components/fh-js-api-core/store/slices/categoriesSlice';
import qs from 'qs';
import redirectWithSavedSort from '@/lib/redirectWithSavedSort';
import redirectFirstPage from '@/lib/redirectFirstPage';
import { TextPage, CatalogPage } from '@/layouts';
import { handleError } from '@/lib/handleError';
import ecommerce from '@/seo/Ecommerce';
import Router from 'next/router';

const NextPage = ({ pageType, ...props }) => {
	useEffect(() => {
		if (pageType === 'redirect') {
			Router.push(props.data);
		}
		if (pageType === 'text-page') {
			if (props.data.slug.includes('contacts') || props.data.slug.includes('store')) {
				ecommerce.setPageType(ecommerce.PAGE_TYPES.CONTACTS);
			} else {
				ecommerce.setPageType(ecommerce.PAGE_TYPES.OTHER);
			}
		}

		if (pageType === 'catalog') {
			if (props.data.current.slug.includes('brands')) {
				ecommerce.setPageType(ecommerce.PAGE_TYPES.BRAND);
			} else {
				ecommerce.setPageType(ecommerce.PAGE_TYPES.CATEGORY);
			}
		}
	}, [pageType]);

	switch (pageType) {
		case 'catalog':
			return <CatalogPage {...props} />;
		case 'text-page':
			return <TextPage {...props} />;
		default:
			return null;
	}
};

NextPage.getInitialProps = async ({ query, reduxStore, res, asPath }) => {
	const {
		typeSlug,
		page: pageQuery,
		sort: selectedSorting,
		filters: selectedFilters,
		access,
	} = qs.parse(query, {
		arrayLimit: 100,
	});

	const pageOrCategorySlug = typeSlug.join('/');

	let selectedCategory;
	let data;
	let isRedirect = false;

	await reduxStore.dispatch(
		getCategoryTreeBySlug({
			slug: `/${pageOrCategorySlug}`,
			onSuccess: result => {
				if (result.page.pageType === 'redirect') {
					isRedirect = true;
					data = result.page.data.to;
				} else {
					data = result.page.data;
					selectedCategory = result.page.data.current;
				}
			},
			onError: result => {
				handleError(result);
			},
		}),
	);

	if (isRedirect) {
		return {
			pageType: 'redirect',
			data,
		};
	}

	const isCatalogLink = selectedCategory?.type === 'menu';
	if (isCatalogLink) {
		redirectFirstPage({ page: pageQuery, res, asPath });

		const savedSort = catalogSortSelector(reduxStore.getState());

		if (selectedSorting && savedSort !== selectedSorting)
			reduxStore.dispatch(saveSort(selectedSorting));
		if (savedSort && !selectedSorting) {
			redirectWithSavedSort({ res, asPath, sort: savedSort });
			return;
		}

		const sortFields = selectedCategory.sort_fields;
		const hasSelectedSortInFields = sortFields.some(field => field.value === selectedSorting);
		const params = {
			hash: selectedCategory.hash,
			page: pageQuery || 1,
			filters: selectedFilters,
			sort: hasSelectedSortInFields ? selectedSorting : (sortFields.length > 0 ? sortFields[0].value : 'new'),
		};

		await reduxStore.dispatch(fetchProducts(params));

		const pageData = getCatalogData(reduxStore.getState());

		handleError(pageData.error);
	} else {
		return {
			pageType: 'text-page',
			slug: pageOrCategorySlug,
			textPageType: 'page',
			access,
			data: data.page,
		};
	}

	return { pageType: 'catalog', data };
};

export default NextPage;
