import Router from 'next/router';

const redirectWithSavedSort = ({ sort, res, asPath }) => {
	const pathname = asPath.split('?')[0];
	const query = asPath.split('?')[1];
	const nextQuery = [...(query?.split('&') ?? []), `sort=${sort}`].join('&');
	const redirectUrl = `${pathname}?${nextQuery}`;
	if (res) {
		res.writeHead(302, {
			Location: redirectUrl,
		});
		res.end();
	} else {
		Router.push(redirectUrl);
	}
};

export default redirectWithSavedSort;
